import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <Box className="footer">
      <Typography variant="body2" color="inherit">
        Apple, Apple logo and macOS are trademarks of Apple Inc., registered in the U.S. and other countries.
      </Typography>
      <Typography variant="body2" color="inherit">&copy;
        2024 Lohr Software. All Rights Reserved.
      </Typography>
      <Button color="inherit" component={Link} to="/impressum" sx={{ mt: 2, color: '#00f5d4' }}>
        Impressum
      </Button>
      <Button color="inherit" component={Link} to="/privacypolicy" sx={{ mt: 2, color: '#00f5d4' }}>
        Privacy Policy
      </Button>
    </Box>
  );
}

export default Footer;
