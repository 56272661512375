import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function Impressum() {
  return (
    <div className="page-container">
      <h1 className="prisma-text">Impressum</h1>
      <Card sx={{ mb: 4, backgroundColor: '#1f1f1f', color: '#ffffff', padding: '20px', borderRadius: '15px', position: 'relative' }}>
        <CardContent>
          <Typography variant="body1" align="left">
            Adrian Lohr
            <br />
            Lohr Software
            <br />
            Arnheidstr. 3
            <br />
            13467 Berlin
            <br />
            Deutschland
            <br />
            <br />
            Tel.: +4915228639973
            <br />
            E-Mail: mail@lohrsoftware.com
            <br />
            <br />
            Umsatzsteuer-Identifikationsnummer: DE400003299
            <br />
            USt. wird nicht ausgewiesen (Kleinunternehmerregelung)
            <br />
            <br />
            Plattform der EU-Kommission zur Online-Streitbeilegung: <Link to="https://ec.europa.eu/odr" style={{ color: '#00f5d4', textDecoration: 'none' }}> https://ec.europa.eu/odr</Link>
            <br />
            <br />
            Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default Impressum;
