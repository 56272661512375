import React from 'react';
import { AppBar, Toolbar, Button } from '@mui/material';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <AppBar position="static" sx={{ backgroundColor: '#000000', boxShadow: 'none' }}>
      <Toolbar>
        <Button color="inherit" component={Link} to="/" sx={{ fontWeight: 'bold', mx: 1, textTransform: 'uppercase', color: '#f0f0f0' }}>
          Home
        </Button>
        <Button color="inherit" component={Link} to="/apps" sx={{ fontWeight: 'bold', mx: 1, textTransform: 'uppercase', color: '#f0f0f0' }}>
          Our Apps
        </Button>
        <Button color="inherit" component={Link} to="/contact" sx={{ fontWeight: 'bold', mx: 1, textTransform: 'uppercase', color: '#f0f0f0' }}>
          Contact
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
