import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

function PrivacyPolicy() {
  return (
    <div className="page-container">
      <h1 className="prisma-text">Privacy Policy</h1>
      <Card sx={{ mb: 4, backgroundColor: '#1f1f1f', color: '#ffffff', padding: '20px', borderRadius: '15px', position: 'relative' }}>
        <CardContent>
          <Typography variant="body1" align="left">
            <h1>Datenschutzerkl&auml;rung</h1>

            <h2>1) Information &uuml;ber die Erhebung personenbezogener Daten und Kontaktdaten des Verantwortlichen</h2>

            <p><b>1.1</b>&nbsp;Wir freuen uns, dass Sie unsere Applikation (nachfolgend &bdquo;App&#8220;) verwenden. Im Folgenden informieren wir Sie &uuml;ber den Umgang mit Ihren personenbezogenen Daten bei der Nutzung unserer App. Personenbezogene Daten sind hierbei alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.</p>

            <p><b>1.2</b>&nbsp;Verantwortlicher f&uuml;r die Datenverarbeitung bez&uuml;glich dieser App im Sinne der Datenschutz-Grundverordnung (DSGVO) ist Adrian Lohr, Lohr Software, Arnheidstr. 3, 13467 Berlin, Deutschland, Tel.: +4915228639973, E-Mail: mail@lohrsoftware.com. Der f&uuml;r die Verarbeitung von personenbezogenen Daten Verantwortliche ist diejenige nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.</p>

            <h2>2) Logfiles bei Nutzung unserer mobilen App</h2>

            <p>Wenn Sie unsere mobile App &uuml;ber einen App-Store herunterladen, werden die erforderlichen Informationen an den App Store &uuml;bertragen, also insbesondere Nutzername, E-Mail-Adresse und Kundennummer Ihres Accounts, Zeitpunkt des Downloads, Zahlungsinformationen und die individuelle Ger&auml;tekennziffer. Auf diese Datenerhebung haben wir keinen Einfluss und sind hierf&uuml;r nicht verantwortlich. Wir verarbeiten die Daten nur, soweit es f&uuml;r das Herunterladen der mobilen App auf Ihr mobiles Endger&auml;t notwendig ist.</p>

            <p>Bei Nutzung unserer mobilen App erheben wir die nachfolgend beschriebenen personenbezogenen Daten, um die komfortable Nutzung der Funktion zu erm&ouml;glichen. Wenn Sie unsere mobile App nutzen m&ouml;chten, erheben wir die folgenden Daten, die f&uuml;r uns technisch erforderlich sind, um Ihnen die Funktionen unserer mobilen App anzubieten und die Stabilit&auml;t und Sicherheit zu gew&auml;hrleisten:</p>

            <ul>
            <li>Datum und Uhrzeit der Anfrage</li>
            <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
            <li>Inhalt der Anforderung</li>
            <li>Zugriffsstatus/ http-Statuscode</li>
            <li>Menge der gesendeten Daten in Byte</li>
            <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
            <li>Verwendeter Browser</li>
            <li>Sprache und Version der Browsersoftware</li>
            <li>Verwendetes Betriebssystem und dessen Oberfl&auml;che</li>
            <li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
            </ul>

            <p>Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilit&auml;t und Funktionalit&auml;t unserer App. Eine Weitergabe oder anderweitige Verwendung der Daten findet nicht statt. Wir behalten uns allerdings vor, die vorbenannten Logfiles nachtr&auml;glich zu &uuml;berpr&uuml;fen, sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.</p>

            <p>Weiterhin ben&ouml;tigen wir Ihre eindeutige Nummer des Endger&auml;ts (IMEI = International Mobile Equipment Identity), eindeutige Nummer des Netzteilnehmers (IMSI = International Mobile Subscriber Identity), Mobilfunknummer (MSISDN), evtl. MAC-Adresse f&uuml;r die WLAN-Nutzung und den Namen Ihres mobilen Endger&auml;tes.</p>

            <h2>3) Kontaktaufnahme</h2>

            <p>Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle der Nutzung eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular in der App ersichtlich. Diese Daten werden ausschlie&szlig;lich zum Zweck der Beantwortung Ihres Anliegens bzw. f&uuml;r die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage f&uuml;r die Verarbeitung dieser Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach abschlie&szlig;ender Bearbeitung Ihrer Anfrage gel&ouml;scht. Dies ist der Fall, wenn sich aus den Umst&auml;nden entnehmen l&auml;sst, dass der betroffene Sachverhalt abschlie&szlig;end gekl&auml;rt ist und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>

            <h2>4) Versand von Push-Benachrichtigungen</h2>

            <p>Sie k&ouml;nnen sich zum Empfang von unseren Push-Benachrichtigungen anmelden. Sie erhalten &uuml;ber unsere Push-Benachrichtigungen regelm&auml;&szlig;ig Informationen &uuml;ber unsere angebotenen Leistungen.</p>

            <p>Zur Anmeldung m&uuml;ssen Sie den Erhalt von Benachrichtigungen best&auml;tigen bzw. in den Einstellungen Ihres Betriebssystems erlauben. Dieser Prozess wird dokumentiert und gespeichert. Hierzu geh&ouml;rt die Speicherung des Anmeldezeitpunkts sowie Ihre Ihre Ger&auml;tekennzeichnung. Die Erhebung dieser Daten ist erforderlich, damit wir zum einen die Push-Benachrichtigungen anzeigen lassen k&ouml;nnen und zum anderen im Falle eines Missbrauchs die Abl&auml;ufe nachvollziehen k&ouml;nnen und dient deshalb unserer rechtlichen Absicherung. Die Verarbeitung dieser Daten erfolgt auf Basis des Art. 6 Abs. 1 lit. a DSGVO.</p>

            <p>Ihre Einwilligung in die Speicherung und Verwendung Ihrer personenbezogenen Daten zum Erhalt unserer Push-Benachrichtigungen und der zuvor beschriebenen statistischen Erhebung k&ouml;nnen Sie jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen. Zum Zwecke des Widerrufs der Einwilligung k&ouml;nnen Sie die dazu vorgesehene Einstellung zum Erhalt von Push Benachrichtigungen in Ihren Einstellungen der App in Ihrem Betriebssystem abbestellen.</p>

            <p>Ihre Daten werden gel&ouml;scht, sobald sie f&uuml;r die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Ihre Daten werden demnach solange gespeichert, wie das Abonnement f&uuml;r unsere Push-Benachrichtigungen aktiv ist.</p>

            <h2>5) Rechte des Betroffenen</h2>

            <p><b>5.1</b>&nbsp;Das geltende Datenschutzrecht gew&auml;hrt Ihnen gegen&uuml;ber dem Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten umfassende Betroffenenrechte (Auskunfts- und Interventionsrechte), &uuml;ber die wir Sie nachstehend informieren:</p>

            <ul>
            <li>Auskunftsrecht gem&auml;&szlig; Art. 15 DSGVO: Sie haben insbesondere ein Recht auf Auskunft &uuml;ber Ihre von uns verarbeiteten personenbezogenen Daten, die Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen Daten, die Empf&auml;nger oder Kategorien von Empf&auml;ngern, gegen&uuml;ber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer bzw. die Kriterien f&uuml;r die Festlegung der Speicherdauer, das Bestehen eines Rechts auf Berichtigung, L&ouml;schung, Einschr&auml;nkung der Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei einer Aufsichtsbeh&ouml;rde, die Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer automatisierten Entscheidungsfindung einschlie&szlig;lich Profiling und ggf. aussagekr&auml;ftige Informationen &uuml;ber die involvierte Logik und die Sie betreffende Tragweite und die angestrebten Auswirkungen einer solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien gem&auml;&szlig; Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittl&auml;nder bestehen;</li>
            <li>Recht auf Berichtigung gem&auml;&szlig; Art. 16 DSGVO: Sie haben ein Recht auf unverz&uuml;gliche Berichtigung Sie betreffender unrichtiger Daten und/oder Vervollst&auml;ndigung Ihrer bei uns gespeicherten unvollst&auml;ndigen Daten;</li>
            <li>Recht auf L&ouml;schung gem&auml;&szlig; Art. 17 DSGVO: Sie haben das Recht, die L&ouml;schung Ihrer personenbezogenen Daten bei Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses Recht besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und Information, zur Erf&uuml;llung einer rechtlichen Verpflichtung, aus Gr&uuml;nden des &ouml;ffentlichen Interesses oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen erforderlich ist;</li>
            <li>Recht auf Einschr&auml;nkung der Verarbeitung gem&auml;&szlig; Art. 18 DSGVO: Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, solange die von Ihnen bestrittene Richtigkeit Ihrer Daten &uuml;berpr&uuml;ft wird, wenn Sie eine L&ouml;schung Ihrer Daten wegen unzul&auml;ssiger Datenverarbeitung ablehnen und stattdessen die Einschr&auml;nkung der Verarbeitung Ihrer Daten verlangen, wenn Sie Ihre Daten zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigen, nachdem wir diese Daten nach Zweckerreichung nicht mehr ben&ouml;tigen oder wenn Sie Widerspruch aus Gr&uuml;nden Ihrer besonderen Situation eingelegt haben, solange noch nicht feststeht, ob unsere berechtigten Gr&uuml;nde &uuml;berwiegen;</li>
            <li>Recht auf Unterrichtung gem&auml;&szlig; Art. 19 DSGVO: Haben Sie das Recht auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung der Verarbeitung gegen&uuml;ber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empf&auml;ngern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder L&ouml;schung der Daten oder Einschr&auml;nkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unm&ouml;glich oder ist mit einem unverh&auml;ltnism&auml;&szlig;igen Aufwand verbunden. Ihnen steht das Recht zu, &uuml;ber diese Empf&auml;nger unterrichtet zu werden.</li>
            <li>Recht auf Daten&uuml;bertragbarkeit gem&auml;&szlig; Art. 20 DSGVO: Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, g&auml;ngigen und maschinenlesbaren Format zu erhalten oder die &Uuml;bermittlung an einen anderen Verantwortlichen zu verlangen, soweit dies technisch machbar ist;</li>
            <li>Recht auf Widerruf erteilter Einwilligungen gem&auml;&szlig; Art. 7 Abs. 3 DSGVO: Sie haben das Recht, eine einmal erteilte Einwilligung in die Verarbeitung von Daten jederzeit mit Wirkung f&uuml;r die Zukunft zu widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten unverz&uuml;glich l&ouml;schen, sofern eine weitere Verarbeitung nicht auf eine Rechtsgrundlage zur einwilligungslosen Verarbeitung gest&uuml;tzt werden kann. Durch den Widerruf der Einwilligung wird die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht ber&uuml;hrt;</li>
            <li>Recht auf Beschwerde gem&auml;&szlig; Art. 77 DSGVO: Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verst&ouml;&szlig;t, haben Sie - unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs - das Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des mutma&szlig;lichen Versto&szlig;es.</li>
            </ul>

            <p><b>5.2</b>&nbsp;WIDERSPRUCHSRECHT</p>

            <p>WENN WIR IM RAHMEN EINER INTERESSENABW&Auml;GUNG IHRE PERSONENBEZOGENEN DATEN AUFGRUND UNSERES &Uuml;BERWIEGENDEN BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG F&Uuml;R DIE ZUKUNFT EINZULEGEN.</p>

            <p>MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN K&Ouml;NNEN, DIE IHRE INTERESSEN, GRUNDRECHTE UND GRUNDFREIHEITEN &Uuml;BERWIEGEN, ODER WENN DIE VERARBEITUNG DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN DIENT.</p>

            <p>WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE K&Ouml;NNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUS&Uuml;BEN.</p>

            <p>MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.</p>

            <h2>6) Dauer der Speicherung personenbezogener Daten</h2>

            <p>Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und &ndash; sofern einschl&auml;gig &ndash; zus&auml;tzlich anhand der jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und steuerrechtliche Aufbewahrungsfristen).</p>

            <p>Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer ausdr&uuml;cklichen Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO werden die betroffenen Daten so lange gespeichert, bis Sie Ihre Einwilligung widerrufen.</p>

            <p>Existieren gesetzliche Aufbewahrungsfristen f&uuml;r Daten, die im Rahmen rechtsgesch&auml;ftlicher bzw. rechtsgesch&auml;fts&auml;hnlicher Verpflichtungen auf der Grundlage von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese Daten nach Ablauf der Aufbewahrungsfristen routinem&auml;&szlig;ig gel&ouml;scht, sofern sie nicht mehr zur Vertragserf&uuml;llung oder Vertragsanbahnung erforderlich sind und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung fortbesteht.</p>

            <p>Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis Sie Ihr Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO aus&uuml;ben, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen, oder die Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.</p>

            <p>Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis Sie Ihr Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO aus&uuml;ben.</p>

            <p>Sofern sich aus den sonstigen Informationen dieser Erkl&auml;rung &uuml;ber spezifische Verarbeitungssituationen nichts anderes ergibt, werden gespeicherte personenbezogene Daten im &Uuml;brigen dann gel&ouml;scht, wenn sie f&uuml;r die Zwecke, f&uuml;r die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.</p>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default PrivacyPolicy;
