import React from 'react';
import '../styles.css';

function Apps() {
  return (
    <div className="page-container">
      <h1 className="prisma-text">Our Apps</h1>

      <div className="app-card">
        <h3>Billable Hours</h3>
        <p>
          Billable Hours is a simple yet powerful app designed for freelancers and small businesses to track their time and generate professional invoices. Keep track of your working hours with ease and never lose out on billable time again.
        </p>
        <a href="https://apps.apple.com/app/xxxx" target="_blank" rel="noopener noreferrer">
            <img src="/MacAppStoreBadge.svg" alt="Download on the Mac App Store" className="app-store-badge" />
        </a>
      </div>

      <div className="app-card">
        <h3>File Morpher</h3>
        <p>
          File Morpher is a versatile app for transforming files into different formats. Whether you need to convert images, documents, or other files, File Morpher is the perfect tool for seamless file conversion.
        </p>
        <a href="https://apps.apple.com/app/xxxx" target="_blank" rel="noopener noreferrer">
            <img src="/MacAppStoreBadge.svg" alt="Download on the Mac App Store" className="app-store-badge" />
        </a>
      </div>
    </div>
  );
}

export default Apps;
