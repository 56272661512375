import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container } from '@mui/material';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Apps from './pages/Apps';
import Contact from './pages/Contact';
import Impressum from './pages/Impressum';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  return (
    <div className="App"
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#000000',
        color: '#e0e0e0',
      }}
    >
      <Navbar />
      <Container component="main" sx={{ mt: 4, mb: 4 }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/apps" element={<Apps />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
      </Container>
      <Footer />
    </div>
  );
}

export default App;
