import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

function Contact() {
  return (
    <div className="page-container">
    <h1 className="prisma-text">Impressum</h1>
      <Card sx={{ mb: 4, backgroundColor: '#1f1f1f', color: '#ffffff', padding: '20px', borderRadius: '15px', position: 'relative' }}>
        <CardContent>
          <Typography variant="body1">
            Contact me via
            Email: <a href="mailto:mail@lohrsoftware.com" style={{ color: '#00f5d4' }}>mail@lohrsoftware.com</a><br />
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default Contact;
