import React from 'react';
import '../styles.css';

function Home() {
  return (
    <div className="page-container">
      <h1 className="prisma-text">Lohr Software</h1>
      <p style={{ marginTop: '20px', maxWidth: '800px', margin: '20px auto', lineHeight: '1.6' }}>
        Welcome to Lohr Software! We create high-quality, easy-to-use apps for Apple platforms. Our passion is to deliver the best user experience and utility for everyone using the macOS and iOS ecosystem.
      </p>
    </div>
  );
}

export default Home;
